$(function() {
    $('body').delegate('.form__input_effect, .form__textarea_effect', 'focusout', function(){
        if($(this).val() != ""){
            $(this).addClass("has-content");
        }else{
            $(this).removeClass("has-content");
        }
    });

    $('.phone').mask("7 (999) 999-99-99");


    $(".footer__menu-nn .footer__menu-btn, .footer__menu-fv .footer__menu-btn").click(function(e) {
        $(this).prev().addClass('open');
    });

    $('.footer__menu').click(function() {
        $(this).toggleClass('open');
    });

    $(".js-integration-list").click(function(e) {
        $(this).toggleClass('open').prev().toggleClass('active');
    });

    $('.js-toggler').on('click', function (event) {
        $('.header__menu').addClass('open');
        $('body').addClass('overfl');
    });
    $('.js-menu-close').on('click', function (event) {
        $('.header__menu').removeClass('open');
        $('body').removeClass('overfl');
    });

    $('.header__menu ul > li').on('click', function (event) {
        $(this).toggleClass('open');
    });

    $('.js-btn-serv-open').on('click', function (event) {
        $(this).parent().parent().parent().toggleClass('open');
    });


    $('.js-license-slider').on('init reInit', function(event, slick) {
        $('.license__inner .number-slide').html('<span class="numb-prev">' + 1 + '</span>' + '<span class="numb-next">' + slick.slideCount + '</span>');
    });
    $('.js-license-slider').on('afterChange', function(event, slick, currentSlide, nextSlide) {
        $('.license__inner .number-slide').html('<span class="numb-prev">' + (currentSlide + 1) + '</span>' + '<span class="numb-next">' + slick.slideCount + '</span>');
    });

    $('.js-license-slider').slick({
        infinite: true,
        dots: true,
        appendDots:$('.pagingInfo .dots'),
        arrows: true
    });

    $('.js-tariffs-slider').slick({
        infinite: false,
        arrows: true,
        dots: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    });








    $('.slider').slick({
        infinite: true,
        arrows: false,
        dots: false,
        autoplay: false,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnHover: true
    });


    var percentTime;
    var tick;
    var isPause;
    var time = 1;
    var progressBarIndex = 0;

    $('.progressBarContainer .progressBar').each(function(index) {
        var progress = "<div class='inProgress inProgress" + index + "'></div>";
        $(this).html(progress);
    });

    function startProgressbar() {
        resetProgressbar();
        percentTime = 0;
        isPause = false;
        tick = setInterval(interval, 10);
    }

    function interval() {
        if (($('.slider .slick-track div[data-slick-index="' + progressBarIndex + '"]').attr("aria-hidden")) === "true") {
            progressBarIndex = $('.slider .slick-track div[aria-hidden="false"]').data("slickIndex");
            startProgressbar();
        } else {

            if (percentTime >= 100) {
                $('.single-item').slick('slickNext');
                progressBarIndex++;
                if (progressBarIndex > 2) {
                    progressBarIndex = 0;
                }
                startProgressbar();
            }

            if (isPause === false) {
                percentTime += 1 / (time + 5);
                $('.inProgress' + progressBarIndex).css({
                    width: percentTime + "%"
                });
                if (percentTime >= 100) {
                    tick.slick('slickNext');
                    startProgressbar();
                }
            }


        }
    }

    function resetProgressbar() {
        $('.inProgress').css({
            width: 0 + '%'
        });
        clearInterval(tick);

    }
    startProgressbar();

    $('.progressBarContainer > div').click(function () {
        clearInterval(tick);
        var goToThisIndex = $(this).find("span").data("slickIndex");
        $('.single-item').slick('slickGoTo', goToThisIndex, false);
        startProgressbar();
    });

    $('.slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        startProgressbar();
    });

    $('.slider').on("mouseenter", function(){
        isPause = true;
    }).on("mouseleave", function(){
        isPause = false;
    })













    ymaps.ready(init);
    function init () {
        var myMap = new ymaps.Map("map", {
            center: [55.77101400, 37.63209300],
            zoom: 13,
            controls: ["zoomControl"]
        });

        myMap.controls.add('fullscreenControl', {float: 'left'});
        myMap.geoObjects
            .add(new ymaps.Placemark([55.77101400, 37.63209300], {
                balloonContent: 'РЈР». РЈР»РёС‡РЅР°СЏ,Рґ. 22'
            }, {
                iconLayout: 'default#image'
            }))

    }
});
